import React from "react"; // importing FunctionComponent
import { Layout } from "antd";

class Ayuda extends React.Component<{}, {}> {
  render() {
    return (
      <Layout.Content
        className="content"
        style={{
          background: "#fff",
          padding: 24,
          margin: 0,
          minHeight: 280,
        }}
      >
        Acá iría una sección de documentación.
      </Layout.Content>
    );
  }
}

export default Ayuda;
