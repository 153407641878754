import React from "react";
import { Menu, MenuProps, Modal } from "antd";
import {
  AlertOutlined,
  GoldOutlined,
  DashboardOutlined,
  TableOutlined,
  ContactsOutlined,
  UnorderedListOutlined,
  BellOutlined,
  PoweroffOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const paths = [
  "/",
  "/streams",
  "/alerts",
  "/alerts/feed",
  "/alerts/history",
  "/viewall",
  "/contacts",
  "",
];

function LeftMenu() {
  const navigate = useNavigate();
  const [showLogoutModal, setShowLogoutModal] = React.useState<boolean>(false);
  const onClick: MenuProps["onClick"] = (e) => {
    if (e.key === "7") {
      setShowLogoutModal(true);
      return;
    }
    navigate(paths[Number(e.key)]);
  };

  const logout = () => {
    sessionStorage.removeItem("token");
    window.location.reload();
    navigate("/");
  };

  const items = [
    getItem("Dashboard", 0, <DashboardOutlined />),
    getItem("Streams", 1, <GoldOutlined />),
    getItem("Alerts", 2, <AlertOutlined />, [
      getItem("Feed", 3, <BellOutlined />),
      getItem("History", 4, <UnorderedListOutlined />),
    ]),
    getItem("Live View", 5, <TableOutlined />),
    getItem("Contacts", 6, <ContactsOutlined />),
    getItem("Logout", 7, <PoweroffOutlined />),
  ];

  return (
    <>
      <Menu
        theme="dark"
        onClick={onClick}
        defaultSelectedKeys={["0"]}
        defaultOpenKeys={["0"]}
        mode="inline"
        items={items}
      ></Menu>
      <Modal
        title="Logout"
        visible={showLogoutModal}
        onOk={logout}
        onCancel={() => setShowLogoutModal(false)}
      >
        <p>Are you sure you want to logout?</p>
      </Modal>
    </>
  );
}

export default LeftMenu;
