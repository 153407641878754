export default class Stream {
  _id: string = "";
  url: string = "";
  last_pid: number = 0;
  name: string = "";
  fps: number = 0;
  data_usage_limit: number = 0;
  data_usage: number = 0;
  transport: string = "tcp";

  public getCapturePath() {
    return window._env_.REACT_APP_PCS_API_URL + "media/results/" + this._id + ".jpg"
  }

  public static from(json : any){
    return Object.assign(new Stream(), json) as Stream;
  }
}
