export default class Alert {
  _id: string = "";
  process_id: string = "";
  timestamp: string = "";
  stream: string = "";
  condition: string = "";
  value: string = "";
  rule: string = "";
  severity: string = "";

  public static from(json: any) {
    return Object.assign(new Alert(), json) as Alert;
  }
}
