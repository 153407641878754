import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useCallback, useEffect, useState } from "react";
import { StatsAPIHelper } from "../../../../../api-helpers/StatsAPIHelper";
import { STYLED_COLORS } from "../../../../../common/constants";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface DailyColorsEntry {
  _id: { day: string; hat_color: string };
  count: number;
}

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    tooltips: {
      callbacks: {
        label: (item: any) => `${item.yLabel}%`,
      },
    },
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
      max: 100,
      ticks: {
        // Include a dollar sign in the ticks
        callback: function (value: any) {
          return value + "%";
        },
      },
    },
  },
};

interface HatColorsDistributionProps {
  startDate: Date;
  endDate: Date;
}

const HAT_COLORS = ["red", "yellow", "blue", "white", "orange", "green"];

export function HatColorsDistribution(props: HatColorsDistributionProps) {
  const [stats, setStats] = useState<Array<DailyColorsEntry>>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const updateContent = useCallback(() => {
    let query_string =
      "timestamp__gte=" +
      props.startDate.toISOString() +
      "&" +
      "timestamp__lte=" +
      props.endDate.toISOString();
    StatsAPIHelper.getDailyHatColorUsage(query_string).then((stats) => {
      if (stats == null) {
        return;
      }
      console.log(stats as Array<DailyColorsEntry>);
      setStats(stats as Array<DailyColorsEntry>);
      setLoading(false);
    });
  }, [props]);

  // ComponentDidMount equivalent
  useEffect(() => {
    updateContent();
    const interval = setInterval(() => {
      updateContent();
    }, 10000);
    // ComponentWillUnmount equivalent
    return () => clearInterval(interval);
  }, [updateContent]);

  const MAX_DAYS = 7;

  var days = [];

  for (let i = 0; i < MAX_DAYS; i++) {
    var ms = new Date().getTime() - i * 86400000;
    var date = new Date(ms);
    var userTimezoneOffset = date.getTimezoneOffset() * 60000;
    date = new Date(date.getTime() - userTimezoneOffset);
    days.push(
      date.toLocaleDateString(undefined, { day: "2-digit", month: "2-digit" })
    );
  }

  const labels = days.reverse();

  const formated_labels = labels.map((l) => l.slice(0, 5));
  console.log(labels);
  console.log(formated_labels);
  let data = {
    labels: formated_labels,
    datasets: HAT_COLORS.map((color) => ({
      label: color,
      data: new Array<number>(MAX_DAYS).fill(0),
      borderColor: STYLED_COLORS[color],
      backgroundColor: STYLED_COLORS[color],
    })),
  };

  if (loading) {
    return <></>;
  }

  stats.forEach((entry) => {
    let dataset_index = HAT_COLORS.indexOf(entry._id.hat_color);
    if (dataset_index === -1) {
      return;
    }
    console.log(dataset_index);
    var parts = entry._id.day.split("-");
    let day_index = formated_labels.indexOf(parts[1] + "/" + parts[2]);
    data.datasets[dataset_index].data[day_index] = entry.count;
  });

  for (let day_index = 0; day_index < labels.length; day_index++) {
    let total = 0;
    data.datasets.forEach((dataset) => {
      total += dataset.data[day_index];
    });
    data.datasets.forEach((dataset) => {
      dataset.data[day_index] = Math.round(
        (dataset.data[day_index] / total) * 100
      );
    });
  }

  return <Bar options={options} data={data} height={"200px"} />;
}
