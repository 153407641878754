import { Button, Card, Modal, Space } from "antd";
import ContactsTable from "./subcomponents/ContactsTable";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useState } from "react";
import NewContactForm from "./subcomponents/NewContactForm";

function ContactList() {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    // Reload window
    window.location.reload();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <Card
          title={
            <Space size={20}>
              Contacts
              <Button
                type="primary"
                shape="round"
                icon={<PlusCircleOutlined />}
                onClick={showModal}
              >
                New
              </Button>
            </Space>
          }
        >
          <ContactsTable />
        </Card>
        <Modal
          title="New Contact"
          visible={isModalOpen}
          footer={null}
          onCancel={handleCancel}
          destroyOnClose
        >
          <NewContactForm
            onFinish={handleOk}
            onCancel={handleCancel}
          ></NewContactForm>
        </Modal>
      </Space>
    </>
  );
}

export default ContactList;
