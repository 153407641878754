import { useState, useEffect, useMemo, useCallback } from "react";
import { Circle, Rect, Layer, Line, Stage, Image } from "react-konva";
import useImage from "use-image";
import { Button, Col, Popconfirm, Row, Space, Spin } from "antd";

export type Point = {
  x: number;
  y: number;
};

type AnchorProps = {
  point: Point;
  fill: string;
  onClick: () => void;
  onMouseOver: () => void;
  onMouseOut: () => void;
};

function Anchor(props: AnchorProps) {
  const [strokeWidth, setStrokeWidth] = useState(2);

  return (
    <Circle
      x={props.point.x}
      y={props.point.y}
      radius={10}
      stroke="#666"
      fill={props.fill}
      strokeWidth={strokeWidth}
      onMouseOver={() => {
        document.body.style.cursor = "pointer";
        setStrokeWidth(3);
        props.onMouseOver();
      }}
      onMouseOut={() => {
        document.body.style.cursor = "default";
        setStrokeWidth(2);
        props.onMouseOut();
      }}
      onClick={() => {
        document.body.style.cursor = "default";
        props.onClick();
      }}
    />
  );
}

type PolygonOriginAnchorProps = {
  point: Point;
  onValidClick: () => void;
  onValidMouseOver: () => void;
  validateMouseEvents: () => boolean;
};

function PolygonOriginAnchor(props: PolygonOriginAnchorProps) {
  const isValid = props.validateMouseEvents();
  const [fill, setFill] = useState("transparent");

  return (
    <Anchor
      point={props.point}
      fill={fill}
      onClick={() => {
        if (isValid) {
          props.onValidClick();
        }
      }}
      onMouseOver={() => {
        if (isValid) {
          document.body.style.cursor = "pointer";
          setFill("#52c41a");
          props.onValidMouseOver();
        } else {
          document.body.style.cursor = "not-allowed";
          setFill("red");
        }
      }}
      onMouseOut={() => {
        setFill("transparent");
      }}
    />
  );
}

type PolygonConstructorProps = {
  closeTargetRadius?: number;
  onNewPoint: (point: Point) => void;
  onComplete: (complete: boolean) => void;
  points: Point[];
  isComplete: boolean;
  height: number;
  width: number;
};

export function PolygonConstructor(props: PolygonConstructorProps) {
  const [nextPoint, setNextPoint] = useState(
    props.points.length !== 0
      ? props.points[props.points.length - 1]
      : { x: 0, y: 0 }
  );

  useEffect(() => {
    if (props.isComplete) {
      setNextPoint(props.points[0]);
    } else {
      setNextPoint(props.points[props.points.length - 1]);
    }
  }, [props.isComplete, props.points]);

  const handleClick = (point: Point) => {
    props.onNewPoint(point);
  };

  return (
    <>
      {props.points.length !== 0 ? (
        <Line
          strokeWidth={3}
          stroke="#1890ff"
          opacity={0.75}
          lineJoin="round"
          closed={props.isComplete}
          points={props.points
            .flatMap((point) => [point.x, point.y])
            .concat([nextPoint.x, nextPoint.y])}
        />
      ) : (
        <></>
      )}

      <Rect
        x={0}
        y={0}
        width={props.width}
        height={props.height}
        onClick={(event) => {
          if (!props.isComplete) {
            const x = event.evt.offsetX;
            const y = event.evt.offsetY;
            handleClick({ x, y });
          }
        }}
        onMouseMove={(event) => {
          if (!props.isComplete) {
            const x = event.evt.offsetX;
            const y = event.evt.offsetY;
            setNextPoint({ x, y });
          }
        }}
      />
      {props.points[0] && !props.isComplete && (
        <PolygonOriginAnchor
          point={props.points[0]}
          onValidClick={() => {
            setNextPoint(props.points[0]);
            props.onComplete(true);
          }}
          onValidMouseOver={() => {
            setNextPoint(props.points[0]);
          }}
          validateMouseEvents={() => {
            return props.points.length > 2;
          }}
        />
      )}
    </>
  );
}

interface ImagePolygonDrawerProps {
  imageUrl: string;
  onNewPoint: (point: Point) => void;
  onComplete: (complete: boolean) => void;
  points: Point[];
  isComplete: boolean;
}

const ImagePolygonDrawer = (props: ImagePolygonDrawerProps) => {
  const [image] = useImage(props.imageUrl);

  const HEIGHT = 600;
  const WIDTH = 800;

  const pointFromLocalCoords = (point: Point) => {
    return {
      x: point.x / WIDTH,
      y: point.y / HEIGHT,
    };
  };

  const pointToLocalCoords = (point: Point) => {
    return {
      x: point.x * WIDTH,
      y: point.y * HEIGHT,
    };
  };

  const local_points = useMemo(
    () => (props.points ? props.points.map(pointToLocalCoords) : []),
    [props.points]
  );

  return (
    <>
      <Stage height={HEIGHT} width={WIDTH}>
        <Layer height={HEIGHT} width={WIDTH}>
          <Image image={image} x={0} y={0} height={HEIGHT} width={WIDTH} />
          <PolygonConstructor
            onNewPoint={(point: Point) =>
              props.onNewPoint(pointFromLocalCoords(point))
            }
            onComplete={props.onComplete}
            points={local_points}
            isComplete={props.isComplete}
            height={HEIGHT}
            width={WIDTH}
          />
        </Layer>
      </Stage>
    </>
  );
};

interface SelectAOIForm {
  imagePath: string;
  onAccept: (points: Point[]) => void;
  onCancel: () => void;
  initialPoints: Point[];
}

function SelectRoiForm(props: SelectAOIForm) {
  const [imageBlob, setImageBlob] = useState<Blob | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [points, setPoints] = useState<Array<Point>>(props.initialPoints);
  const [isComplete, setIsComplete] = useState<boolean>(
    props.initialPoints.length !== 0
  );

  const updateImage = useCallback(() => {
    fetch(props.imagePath)
      .then((response) => response.blob())
      .then((imageBlob) => {
        if (imageBlob === null || imageBlob.size === 0 || imageBlob.type !== "image/jpg") {
          throw new Error("Unable to fetch the live stream.");
        }
        setImageBlob(imageBlob);
        setLoading(false);
      })
      .catch(() => {
        updateImage();
      });
  }, [props.imagePath]);

  // ComponentDidMount equivalent
  useEffect(() => {
    updateImage();
  }, [updateImage]);

  let imageObjectURL = useMemo(() => {
    return imageBlob ? URL.createObjectURL(imageBlob) : "";
  }, [imageBlob]);

  const onNewPoint = (new_point: Point) => {
    setPoints([...points, new_point]);
  };

  const onComplete = (complete: boolean) => {
    setIsComplete(complete);
  };

  const onCancel = () => {
    setPoints(props.initialPoints);
    setIsComplete(props.initialPoints.length !== 0);
    props.onCancel();
  };

  const onUndo = () => {
    let new_points = points.slice(0, -1);
    if (isComplete) {
      setIsComplete(false);
    } else {
      setPoints(new_points);
    }
  };

  let acceptEnabled = points.length === 0 || isComplete;

  return (
    <Spin spinning={loading}>
      <Row align="middle" justify="center" gutter={[15, 15]}>
        <Col span={24}>
          <ImagePolygonDrawer
            imageUrl={imageObjectURL}
            onNewPoint={onNewPoint}
            onComplete={onComplete}
            points={points}
            isComplete={isComplete}
          />
        </Col>
        <Col span={24} style={{ textAlign: "center" }}>
          <p className="ant-upload-hint">
            Select the area of interest by clicking on the image, leave it blank
            to select the whole image.
          </p>
        </Col>
        <Col>
          <Space>
            <Button
              type="primary"
              disabled={!acceptEnabled}
              onClick={() => {
                props.onAccept(points);
              }}
            >
              Accept
            </Button>
            {points.length === 0 ? (
              <Popconfirm
                title={"Are you sure?"}
                onConfirm={() => {
                  onCancel();
                }}
              >
                <Button type="primary" danger>
                  Cancel
                </Button>
              </Popconfirm>
            ) : (
              <Button type="primary" danger onClick={onUndo}>
                Undo Last Point
              </Button>
            )}
            <Popconfirm
              title={"Clear all points?"}
              onConfirm={() => {
                setPoints([]);
                setIsComplete(false);
              }}
            >
              <Button type="primary" danger>
                Clear All
              </Button>
            </Popconfirm>
            <Button type="default" onClick={updateImage}>
                Reload
              </Button>
          </Space>
        </Col>
      </Row>
    </Spin>
  );
}

export default SelectRoiForm;
