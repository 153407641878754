import { Checkbox, InputNumber } from "antd";
import { PCSAlertCondition } from "../../../../../../models/PCSAlertRule";

interface ChoiceConditionConfigProps {
  condition: PCSAlertCondition;
  choices: Array<string>;
  onValueChanged?: (value: any) => void;
}

function ChoiceConditionConfig(props: ChoiceConditionConfigProps) {
  return (
    <Checkbox.Group
      options={props.choices.map(
        (choice) => choice[0].toUpperCase() + choice.slice(1)
      )}
      value={
        props.condition.value
          ? props.condition.value.map(
              (choice: any) =>
                choice[0].toString().toUpperCase() + choice.slice(1)
            )
          : []
      }
      onChange={(value) => {
        props.onValueChanged &&
          props.onValueChanged(value.map((v) => v.toString().toLowerCase()));
      }}
    />
  );
}

interface EscalarConditionConfigProps {
  condition: PCSAlertCondition;
  onValueChanged?: (value: any) => void;
}

function EscalarConditionConfig(props: EscalarConditionConfigProps) {
  return (
    <InputNumber
      value={props.condition.value}
      onChange={(value) => {
        props.onValueChanged && props.onValueChanged(value);
      }}
    />
  );
}

const CONDITIONS_OPTIONS: { [name: string]: any } = {
  hat: {
    type: "choice",
    choices: ["none", "red", "yellow", "blue", "white", "orange", "green"],
  },
  vest: {
    type: "choice",
    choices: ["none", "yellow", "orange"],
  },
  clothes_color: {
    type: "choice",
    choices: [
      "black",
      "white",
      "red",
      "green",
      "blue",
      "yellow",
      "orange",
      "pink",
      "brown",
      "grey",
    ],
  },
  count_lt: {
    type: "escalar",
  },
  count_gt: {
    type: "escalar",
  },
};

interface ConditionConfigProps {
  condition: PCSAlertCondition;
  onValueChanged?: (value: any) => void;
}

function ConditionConfig(props: ConditionConfigProps) {
  const conditionType = props.condition.type;
  const conditionConfig = CONDITIONS_OPTIONS[conditionType];
  const onValueChanged = props.onValueChanged;

  console.log("conditionConfig", conditionConfig);

  switch (conditionConfig.type) {
    case "choice":
      return (
        <ChoiceConditionConfig
          condition={props.condition}
          choices={conditionConfig.choices}
          onValueChanged={onValueChanged}
        />
      );
    case "escalar":
      return (
        <EscalarConditionConfig
          condition={props.condition}
          onValueChanged={onValueChanged}
        />
      );
    default:
      return <div>Unknown condition type: {conditionType}</div>;
  }
}

export default ConditionConfig;
