import { useCallback, useEffect, useState } from "react";
import {
  Table,
  Spin,
  Card,
  Button,
  DatePicker,
  Form,
  MenuProps,
  Divider,
  Space,
} from "antd";
import { AlertsAPIHelper } from "../../../../../api-helpers/AlertsAPIHelper";
import { StreamsAPIHelper } from "../../../../../api-helpers/StreamsAPIHelper";
import { DownloadOutlined, FilterOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import moment from "moment";
import locale from "antd/es/date-picker/locale/es_ES";
import dayjs from "dayjs";
import Dropdown from "antd/es/dropdown/dropdown";
import { capitalizeFirstLetter, severityToBadge } from "./utils";

interface StreamIN {
  id: string;
  name: string;
}

interface MenuItem {
  key: string;
  label: string;
}

interface AlertsHistoryProps {
  streamId: string;
}

interface AlertHistoryTableItem {
  timestamp: string;
  stream: string;
  rule: string;
  picture: string;
  condition: string;
  value: string;
  severity: string;
  _id: string;
}

function fixDate(dateTimeUTC: string): string {
  const DateUTC = new Date(dateTimeUTC);
  const argentinaTimeOffset = new Date().getTimezoneOffset();
  const dateTimeInArgentina = new Date(DateUTC.getTime() - argentinaTimeOffset * 60000);
  const formattedDateTime = `${dateTimeInArgentina.toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })}, ${dateTimeInArgentina.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  })}`;
  return formattedDateTime;
}

const getInitialDate = () => {
  let today_start = new Date();
  today_start.setMinutes(0);
  today_start.setMilliseconds(0);
  today_start.setSeconds(0);
  today_start.setHours(0);
  today_start.setMinutes(0);
  return today_start;
};

const getTomorrowStart = () => {
  let tomorrow_start = new Date();
  tomorrow_start.setDate(tomorrow_start.getDate() + 1);
  tomorrow_start.setMinutes(0);
  tomorrow_start.setMilliseconds(0);
  tomorrow_start.setSeconds(0);
  tomorrow_start.setHours(0);
  tomorrow_start.setMinutes(0);
  return tomorrow_start;
};

const columns = [
  {
    title: "Picture",
    key: "picture",
    render: (item: AlertHistoryTableItem) => {
      return (
        <Link to={"/alerts/" + item._id}>
          <img
            src={item.picture}
            alt={"Unable to load the live stream."}
            height={"50px"}
          />
        </Link>
      );
    },
  },
  {
    title: "Timestamp",
    key: "timestamp",
    render: (item: AlertHistoryTableItem) => {
      return (
        fixDate(item.timestamp)
      );
    },
  },
  {
    title: "Stream",
    key: "stream",
    render: (item: AlertHistoryTableItem) => {
      return item.stream;
    },
  },
  {
    title: "Rule",
    key: "rule",
    render: (item: AlertHistoryTableItem) => {
      return item.rule;
    },
  },
  {
    title: "Condition",
    key: "condition",
    render: (item: AlertHistoryTableItem) => {
      return capitalizeFirstLetter(item.condition);
    },
  },
  {
    title: "Detected",
    key: "value",
    render: (item: AlertHistoryTableItem) => {
      return capitalizeFirstLetter(item.value);
    },
  },
  {
    title: "Severity",
    key: "severity",
    render: (item: AlertHistoryTableItem) => {
      return (
        <Space>
          {severityToBadge(item.severity)}
          {capitalizeFirstLetter(item.severity)}
        </Space>
      );
    },
  },
];

const CONDITION_TYPES = [
  "hat",
  "vest",
  "clothes_color",
  "count_lt",
  "count_gt",
  "Any"
];

const conditions = CONDITION_TYPES.map((c, index) => ({
  key: index.toString(),
  label: c,
}));

function AlertHistoryTable(props: AlertsHistoryProps) {
  const [start_date, setStartDate] = useState<string>(
    getInitialDate().toISOString()
  );
  const [end_date, setEndDate] = useState<string>(
    getTomorrowStart().toISOString()
  );
  const [condition, setCondition] = useState<string>("Any");
  const [rule, setRule] = useState<string>("Any");
  const [rules, setRules] = useState<Array<MenuItem>>([]);
  const [stream, setStream] = useState<string>("Any");
  const [streams, setStreams] = useState<Array<MenuItem>>([]);
  const [streamINs, setStreamINs] = useState<Array<StreamIN>>([]);

  const [alertsHistoryTableItems, setAlertsHistoryTableItems] = useState<
    Array<AlertHistoryTableItem>
  >([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [total, setTotal] = useState<number | null>(null);
  
  const onStartDateChanged = (date: any, date_string: string) => {
    if (date !== null) {
      setStartDate(moment(date_string).utc().format());
    }
  };

  const onEndDateChanged = (date: any, date_string: string) => {
    if (date !== null) {
      setEndDate(moment(date_string).utc().format());
    }
  };

  const handleMenuConditionClick: MenuProps["onClick"] = (e) => {
    setCondition(conditions?.find((item) => item?.key === e.key)?.label ?? "Any");
  };
  const handleMenuRuleClick: MenuProps["onClick"] = (e) => {
    setRule(rules?.find((item) => item?.key === e.key)?.label ?? "Any");
  };
  const handleMenuStreamClick: MenuProps["onClick"] = (e) => {
    setStream(streams?.find((item) => item?.key === e.key)?.label ?? "Any");
  };

  const onFilter = () => {
    const stream_ = streamINs.find((item) => item.name === stream)?.id ?? "Any"
    let qp =
      "order_by=-timestamp" +
        "&timestamp__gte=" +
        start_date +
        "&timestamp__lte=" +
        end_date +
        (stream_ !== "Any" ? "&stream=" + stream_ : "" )+
        (condition !== "Any" ? "&condition=" + condition : "" )+
        (rule !== "Any" ? "&rule=" + rule : "");
    Promise.all([
      AlertsAPIHelper.getAllAsync(qp),
      StreamsAPIHelper.getAllAsync(),
    ]).then(([alerts, streams_]) => {
      if (alerts === null || streams_ === null) {
        setAlertsHistoryTableItems([]);
        setTotal(0);
        return;
      }

      const alertHistoryTableItems_: AlertHistoryTableItem[] = alerts.map(
        (a) => {
          const streamName =
            streams_.find((s) => s._id === a.stream)?.name ?? "No encontrado";
          const alertHistoryTableItem: AlertHistoryTableItem = {
            timestamp: a.timestamp,
            stream: streamName,
            rule: a.rule,
            picture:
              window._env_.REACT_APP_PCS_API_URL +
              "media/results/alerts/" +
              a._id +
              ".jpg",
            condition: a.condition,
            severity: a.severity,
            value: a.value,
            _id: a._id,
          };
          return alertHistoryTableItem;
        }
      );

      setAlertsHistoryTableItems(alertHistoryTableItems_);
      setTotal(alertHistoryTableItems_.length);
      setLoading(false);
    });
  };

  const convertToCSV = (data: AlertHistoryTableItem[]) => {
    const csvContent = [
      [
        "Timestamp",
        "Stream",
        "Rule",
        "Condition",
        "Severity",
        "Detected",
        "Picture",
      ],
      ...data.map((item) => [
        item.timestamp,
        item.stream,
        item.rule,
        item.condition,
        item.severity,
        item.value,
        item.picture,
      ]),
    ];

    const csvRows = csvContent.map((row) => row.join(",")).join("\n");
    return csvRows;
  };

  const downloadCSV = () => {
    const csvData = convertToCSV(alertsHistoryTableItems);

    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "alerts.csv";
    a.click();

    URL.revokeObjectURL(url);
  };

  const updateContent = useCallback(() => {
    setLoading(true);
    setTotal(null);
    let qp = "order_by=-timestamp";

    Promise.all([
      AlertsAPIHelper.getAllAsync(qp),
      StreamsAPIHelper.getAllAsync(),
    ]).then(([alerts, streams_]) => {
      if (alerts === null || streams_ === null) {
        return;
      }

      const alertHistoryTableItems_: AlertHistoryTableItem[] = alerts.map(
        (a) => {
          const streamName =
            streams_.find((s) => s._id === a.stream)?.name ?? "No encontrado";
          const alertHistoryTableItem: AlertHistoryTableItem = {
            timestamp: a.timestamp,
            stream: streamName,
            rule: a.rule,
            picture:
              window._env_.REACT_APP_PCS_API_URL +
              "media/results/alerts/" +
              a._id +
              ".jpg",
            condition: a.condition,
            severity: a.severity,
            value: a.value,
            _id: a._id,
          };
          return alertHistoryTableItem;
        }
      );
      const streams__ = streams_.map((s, index) => ({
        key: index.toString(),
        label: s.name,
      }));
      const streamsINs_ = streams_.map((s) => ({
        id: s._id,
        name: s.name,
      }));
      const uniqueRules = new Set<string>();
      alertHistoryTableItems_.forEach((item) => {
        uniqueRules.add(item.rule);
      });
      const rules_ = Array.from(uniqueRules).map((r, index) => ({
        key: index.toString(),
        label: r,
      }));
      streams__.push({key:"-1",label:"Any"})
      rules_.push({key:"-1",label:"Any"})
      setStreams(streams__);
      setStreamINs(streamsINs_);
      setRules(rules_);
      setAlertsHistoryTableItems(alertHistoryTableItems_);
      setTotal(alertHistoryTableItems_.length);
      setLoading(false);
    });
  }, [props.streamId]);

  // ComponentDidMount equivalent
  useEffect(() => {
    updateContent();
    const interval = setInterval(() => {
      updateContent();
    }, 100000);
    // ComponentWillUnmount equivalent
    return () => clearInterval(interval);
  }, [updateContent]);

  return (
    <Spin spinning={loading}>
      <Card
        title={total === null ? "" : "Total: " + total.toString()}
        extra={
          <Button
            type="primary"
            shape="round"
            icon={<DownloadOutlined />}
            onClick={downloadCSV}
          >
            Export CSV
          </Button>
        }
      >
        <Form layout="inline">
          <Form.Item name="start_date" label="Desde">
            <DatePicker
              format="YYYY-MM-DD HH:mm"
              showTime={true}
              placeholder={"Seleccione fecha"}
              showNow={false}
              locale={locale}
              defaultValue={dayjs(start_date)}
              allowClear={false}
              onChange={onStartDateChanged}
            />
          </Form.Item>
          <Form.Item name="start_date" label="Hasta">
            <DatePicker
              format="YYYY-MM-DD HH:mm"
              showTime={true}
              placeholder={"Seleccione fecha"}
              showNow={false}
              locale={locale}
              defaultValue={dayjs(end_date)}
              allowClear={false}
              onChange={onEndDateChanged}
            />
          </Form.Item>
          <Form.Item name="rule" label="Rule">
            <Dropdown
              menu={{ items: rules, onClick: handleMenuRuleClick }}
              placement="bottom"
              arrow={{ pointAtCenter: true }}
            >
              <Button>{rule}</Button>
            </Dropdown>
          </Form.Item>
          <Form.Item name="condition" label="Condition">
            <Dropdown
              menu={{ items: conditions, onClick: handleMenuConditionClick }}
              placement="bottom"
              arrow={{ pointAtCenter: true }}
            >
              <Button>{condition}</Button>
            </Dropdown>
          </Form.Item>
          <Form.Item name="stream" label="Stream">
            <Dropdown
              menu={{ items: streams, onClick: handleMenuStreamClick }}
              placement="bottom"
              arrow={{ pointAtCenter: true }}
            >
              <Button>{stream}</Button>
            </Dropdown>
          </Form.Item>
          <Button
            type="primary"
            shape="round"
            onClick={onFilter}
            icon={<FilterOutlined />}
          >
            Apply Filters
          </Button>
        </Form>
        <Divider />
        <Table
          columns={columns}
          dataSource={alertsHistoryTableItems}
          rowKey="id"
          scroll={{ x: true }}
        />
      </Card>
    </Spin>
  );
}

export default AlertHistoryTable;
