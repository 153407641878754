import { useCallback, useEffect, useState } from "react";
import { Card, Row, Col, Spin, Descriptions, Space } from "antd";
import Alert from "../../../../models/Alert";
import { Link, useParams } from "react-router-dom";
import { AlertsAPIHelper } from "../../../../api-helpers/AlertsAPIHelper";
import { StreamsAPIHelper } from "../../../../api-helpers/StreamsAPIHelper";
import Stream from "../../../../models/Stream";
import FrameComponent from "../../../helpers/FrameComponent";
import HeaderStream from "../streams/details/subcomponents/HeaderStream";
import { severityToBadge, capitalizeFirstLetter } from "./subcomponents/utils";

interface AlertDetailsProps {}

function fixDate(dateTimeUTC: string): string {
  const DateUTC = new Date(dateTimeUTC);
  const argentinaTimeOffset = new Date().getTimezoneOffset();
  const dateTimeInArgentina = new Date(DateUTC.getTime() - argentinaTimeOffset * 60000);
  const formattedDateTime = `${dateTimeInArgentina.toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })}, ${dateTimeInArgentina.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  })}`;
  return formattedDateTime;
}

function AlertDetails(props: AlertDetailsProps) {
  const [alert, setAlert] = useState<Alert>(new Alert());
  const [loading, setLoading] = useState<boolean>(true);
  const [stream, setStream] = useState<Stream>(new Stream());

  let { alertId } = useParams();

  const updateContent = useCallback(() => {
    if (alertId === undefined) {
      return;
    }
    AlertsAPIHelper.getSingleAsync(alertId).then((a) => {
      if (a === null) {
        return;
      }
      console.log(a);
      setAlert(a);
      let stream_id = a.stream;
      if (stream_id === undefined) {
        return;
      }
      StreamsAPIHelper.getSingleAsync(stream_id).then((stream) => {
        if (stream === null) {
          return;
        }
        setStream(stream);
        setLoading(false);
      });
      // fetch("http://localhost:9000/api/filefromts/", {
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify({
      //     videsource: stream_id,
      //     timestamp: new Date(a.timestamp["$date"]).toISOString(),
      //   }),
      // }).then((response) => {
      //   response.json().then((body) => { console.log(body)});
      // });
    });
  }, [alertId]);

  // ComponentDidMount equivalent
  useEffect(() => {
    updateContent();
    const interval = setInterval(() => {
      updateContent();
    }, 10000);
    // ComponentWillUnmount equivalent
    return () => clearInterval(interval);
  }, [updateContent]);

  if (!window._env_.REACT_APP_PCS_API_URL) {
    return <></>;
  }
  if (!window._env_.REACT_APP_PCS_API_URL) {
    return <></>;
  }
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card>
            <Row gutter={[16, 16]}>
              <Col span={4}>
                <img
                  src={
                    window._env_.REACT_APP_PCS_API_URL +
                    "media/results/alerts/" +
                    alertId +
                    ".jpg"
                  }
                  alt={"Unable to load the live stream."}
                  width={"100%"}
                  style={{
                    maxHeight: "50vh",
                  }}
                ></img>
              </Col>
              <Col span={20}>
                <Descriptions
                  title={alert.rule + " Alert"}
                  layout={"vertical"}
                  bordered
                  column={3}
                >
                  <Descriptions.Item label="Stream" span={3}>
                    <Link to={"/streams/" + stream._id.toString()}>
                      {stream.name}
                    </Link>
                  </Descriptions.Item>
                  <Descriptions.Item label="Timestamp" span={3}>
                    {fixDate(alert.timestamp)}
                  </Descriptions.Item>
                  <Descriptions.Item label="Severity" span={3}>
                    <Space>
                      {severityToBadge(alert.severity)}
                      {capitalizeFirstLetter(alert.severity)}
                    </Space>
                  </Descriptions.Item>
                  <Descriptions.Item label="Condition">
                    {capitalizeFirstLetter(alert.condition)}
                  </Descriptions.Item>
                  <Descriptions.Item label="Detected">
                    {capitalizeFirstLetter(alert.value)}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xl={8} lg={24} flex="auto">
          <Link to={"/streams/" + stream._id.toString()}>
            <HeaderStream stream={stream} />
          </Link>
        </Col>
        <Col xl={16} lg={24}>
          <Link to={"/streams/" + stream._id.toString()}>
            <Card title="Live View" style={{ width: "100%", height: "100%" }}>
              <Spin spinning={loading}>
                <FrameComponent
                  path={
                    window._env_.REACT_APP_PCS_API_URL + "frames/" + stream._id
                  }
                />
              </Spin>
            </Card>
          </Link>
        </Col>
      </Row>
      {/* <Card title="Video Cut">
        <Row>
          <video
            src="http://localhost:9000/2/2022-06-26T19:02:02.mp4"
            controls
          ></video>
        </Row>
      </Card> */}
    </>
  );
}

export default AlertDetails;
