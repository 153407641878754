import { Badge } from "antd";

export function severityToBadge(severity: string) {
  switch (severity) {
    case "low":
      return <Badge status="success" />;
    case "medium":
      return <Badge status="warning" />;
    case "high":
      return <Badge status="error" />;
    default:
      return <Badge status="default" />;
  }
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
