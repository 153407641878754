export interface PCSAlertCondition {
  type: string;
  value: any;
}

export class PCSAlertRule {
  _id: string = "";
  idx: number = 0;
  name: string = "";
  aoi: number[][] = [];
  aoi_color: number[] = [0, 0, 0];
  active_days: number[] = [0, 1, 2, 3, 4, 5, 6];
  start_time: string = "00:00:00";
  stop_time: string = "23:59:59";
  cooldown_secs: number = 5 * 60;
  min_delay_secs: number = 5;
  severity: string = "medium";
  stream: string = "";
  filters: PCSAlertCondition[] = [];
  triggers: PCSAlertCondition[] = [];
  recipients: string[] = [];

  public static from(json: any) {
    return Object.assign(new PCSAlertRule(), json) as PCSAlertRule;
  }
}
