import { useCallback, useEffect, useState } from "react";
import { Row, Col, Spin } from "antd";
import { AlertsAPIHelper } from "../../../../../api-helpers/AlertsAPIHelper";
import Alert from "../../../../../models/Alert";
import AlertCard from "./AlertCard";

interface AlertsFeedProps {
  streamId: string;
}

function AlertsFeed(props: AlertsFeedProps) {
  const [alerts, setAlerts] = useState<Array<Alert>>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const updateContent = useCallback(() => {
    let queryParams = "order_by=-timestamp&limit=12";
    if (props.streamId !== "") {
      queryParams += "&stream=" + props.streamId;
    }
    AlertsAPIHelper.getAllAsync(queryParams).then((alerts) => {
      if (alerts === null) {
        return;
      }
      setAlerts(alerts);
      setLoading(false);
    });
  }, [props.streamId]);

  // ComponentDidMount equivalent
  useEffect(() => {
    updateContent();
    const interval = setInterval(() => {
      updateContent();
    }, 10000);
    // ComponentWillUnmount equivalent
    return () => clearInterval(interval);
  }, [updateContent]);

  return (
    <Spin spinning={loading}>
      <Row gutter={[16, 16]}>
        {alerts.map((a) => {
          return (
            <Col lg={8} md={12} sm={24}>
              <AlertCard alert={a} />
            </Col>
          );
        })}
      </Row>
    </Spin>
  );
}

export default AlertsFeed;
