import { Descriptions, Card, Tag, Space, Col, Row } from "antd";
import { Link } from "react-router-dom";
import Stream from "../../../../../../models/Stream";

interface HeaderStreamProps {
  stream: Stream;
}

export function HeaderStream(props: HeaderStreamProps) {
  var status = true
  return (
    <Card title="Stream Details" style={{ width: "100%", height: "100%" }}>
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <Descriptions layout={"horizontal"} bordered>
          <Descriptions.Item label="Name" span={3}>
              {props.stream.name}
          </Descriptions.Item>
          <Descriptions.Item label="Status" span={3}>
            <Tag color={status ? "green" : "volcano"}>
              {status ? "Online" : "Offline"}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item label="FPS" span={3}>
            {props.stream.fps}
          </Descriptions.Item>
        </Descriptions>
        <Row justify="center" align="middle">
          <Col>
            <Link to="/stream">
              {" "}
            </Link>
          </Col>
        </Row>
      </Space>
    </Card>
  );
}

export default HeaderStream;
