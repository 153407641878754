const API_RESOURCE = "login/";

export class LoginAPIHelper {
  public static async login(username: string, password: string) {
    const response = await fetch(window._env_.REACT_APP_PCS_API_URL + API_RESOURCE + "access-token", {
        method: 'POST',
        headers:{
          'Content-Type': 'application/x-www-form-urlencoded'
        },    
        body: new URLSearchParams({
            'username': username,
            'password': password
        })
    });
    let token_data = await (response.json()).catch(
      (e) => {
        console.log(e)
        return null;
      }
    );
    return token_data["access_token"]
  }
}
