import React from "react"; // importing FunctionComponent
import { Layout } from "antd";
import AlertsFeedSubcomponent from "./subcomponents/AlertsFeedSubcomponent";

interface AlertasProps {}
interface AlertasState {}

class AlertFeed extends React.Component<AlertasProps, AlertasState> {
  render() {
    return (
      <Layout.Content
        className="content"
        style={{
          padding: 24,
          margin: 0,
          minHeight: 280,
        }}
      >
        <AlertsFeedSubcomponent streamId=""></AlertsFeedSubcomponent>
      </Layout.Content>
    );
  }
}

export default AlertFeed;
