import Alert from "../models/Alert";

const API_RESOURCE = "alerts/";

export class AlertsAPIHelper {
  public static async getAllAsync(query_string: string = "") {
    const response = await fetch(
      window._env_.REACT_APP_PCS_API_URL + API_RESOURCE + "?" + query_string
    );
    let alert_dtos = await (response.json() as Promise<Alert[]>).catch(
      (e) => {
        console.log(e)
        return null;
      }
    );
    if (alert_dtos === null) {
      return null;
    }
    return alert_dtos.map((dto) => {
      return Alert.from(dto);
    });
  }

  public static async getSingleAsync(id: string) {
    const response = await fetch(
      window._env_.REACT_APP_PCS_API_URL + API_RESOURCE + id + "/"
    );
    console.log(response)
    let alert_dto = await (response.json()).catch(
      (e) => {
        console.log(e)
        return null;
      }
    );
    if (alert_dto === null) {
      return null;
    }
    return Alert.from(alert_dto);
  }
}
