import { Modal, Form, Select, Space, Button } from "antd";


interface NewConditionModalProps {
    types: Array<string>;
    onFinish: (type: string) => void;
    onCancel: () => void;
}

function NewConditionModal(props: NewConditionModalProps) {
    const onFinish = (values: any) => {
        props.onFinish(values["type"]);
    };

    return (
        <Modal
            title="Create New Condition"
            visible={true}
            onCancel={props.onCancel}
            footer={null}
        >
            <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                onFinish={onFinish}
            >
                <Form.Item
                    label="Condition Type"
                    name="type"
                    rules={[
                        {
                            required: true,
                            message: "Please enter a valid condition type!",
                        },
                    ]}
                >
                    <Select>
                        {props.types.map((type) => (
                            <Select.Option value={type}>{type}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 8 }}>
                    <Space>
                        <Button type="primary" htmlType="submit">
                            Create Condition
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default NewConditionModal;
