import { Form, Input, Button, Space, notification } from "antd";
import Contact from "../../../../../models/Contact";
import { ContactsAPIHelper } from "../../../../../api-helpers/ContactsAPIHelper";

interface NewContactFormProps {
  onFinish: () => void;
  onCancel: () => void;
  edit?: boolean;
  contact?: Contact;
}

const wrapperColProps = {
  sm: { push: 8, span: 16, flex: "auto" },
  xs: { offset: 0, span: 18 },
};

function NewContactForm(props: NewContactFormProps) {
  const onFinish = (values: any) => {
    let contact = new Contact();
    contact.name = values["name"];
    contact.email = values["email"];
    contact.phone = values["phone"];
    if (props.edit && props.contact) {
      contact._id = props.contact._id;
      ContactsAPIHelper.updateSingleAsync(contact).then((r) => {
        if (r) {
          notification.success({
            message: "Contact Updated",
            description: "Contact updated successfully",
          });
        } else {
          notification.error({
            message: "Error",
            description: "Error while updating the contact.",
          });
        }
      });
    } else {
      ContactsAPIHelper.createSingleAsync(contact).then((r) => {
        if (r) {
          notification.success({
            message: "Contact Created",
            description: "Contact created successfully",
          });
        } else {
          notification.error({
            message: "Error",
            description: "Error while creating the contact.",
          });
        }
      });
    }
    props.onFinish();
  };

  return (
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: "Please input a valid name." }]}
        initialValue={props.contact ? props.contact.name : undefined}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Email"
        name="email"
        rules={[{ required: true, message: "Please input a valid email." }]}
        initialValue={props.contact ? props.contact.email : undefined}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Phone"
        name="phone"
        rules={[
          { required: true, message: "Please input a valid phone number" },
        ]}
        initialValue={props.contact ? props.contact.phone : undefined}
      >
        <Input />
      </Form.Item>
      <Form.Item wrapperCol={wrapperColProps}>
        <Space>
          <Button type="primary" htmlType="submit" style={{ minWidth: "80px" }}>
            Save
          </Button>
          <Button
            type="primary"
            danger
            style={{ minWidth: "80px" }}
            onClick={props.onCancel}
          >
            Cancel
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}

export default NewContactForm;
