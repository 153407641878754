import { useCallback, useEffect, useState } from "react";
import { Table, Button, Spin, Modal, Popconfirm, Breakpoint } from "antd";
import { Link } from "react-router-dom";
import { EditFilled, DeleteFilled } from "@ant-design/icons";
import Contact from "../../../../../models/Contact";
import { ContactsAPIHelper } from "../../../../../api-helpers/ContactsAPIHelper";
import NewContactForm from "./NewContactForm";

interface ContactsTableProps {}

function ContactsTable(props: ContactsTableProps) {
  const [contacts, setContacts] = useState<Array<Contact>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [editingContact, setEditingContact] = useState<Contact | null>(null);

  const columns = [
    {
      title: "ID",
      dataIndex: "idx",
      key: "idx",
      responsive: ["lg"] as Breakpoint[],
    },
    {
      title: "Name",
      key: "name",
      render: (item: Contact) => (
        <Link to={"/contacts/" + item._id.toString()}>{item.name}</Link>
      ),
    },
    {
      title: "Number",
      dataIndex: "phone",
      key: "phone",
      responsive: ["lg"] as Breakpoint[],
    },
    {
      title: "Email",
      dataIndex: "email",
      responsive: ["lg"] as Breakpoint[],
      key: "email",
    },
    {
      title: "",
      render: (item: Contact) => (
        <Button
          type="primary"
          shape="round"
          icon={<EditFilled />}
          onClick={() => {
            onEditContact(item);
          }}
        ></Button>
      ),
    },
    {
      title: "",
      render: (item: Contact) => (
        <Popconfirm
          title="Are you sure you want to delete this contact?"
          onConfirm={() => {
            onDeleteContact(item);
          }}
          okText="Yes"
          cancelText="No"
        >
          <Button
            type="primary"
            shape="round"
            icon={<DeleteFilled />}
            danger
          ></Button>
        </Popconfirm>
      ),
    },
  ];

  const updateContent = useCallback(() => {
    ContactsAPIHelper.getAllAsync().then((contacts) => {
      if (contacts == null) {
        return;
      }
      console.log(contacts);
      setContacts(contacts);
      setLoading(false);
    });
  }, []);

  const onEditContact = (contact: Contact) => {
    setEditingContact(contact);
  };

  const onDeleteContact = (contact: Contact) => {
    ContactsAPIHelper.deleteOneAsync(contact._id).then(() => {
      updateContent();
    });
  };

  const handleCancel = () => {
    setEditingContact(null);
  };

  const handleOk = () => {
    setEditingContact(null);
    updateContent();
  };

  // ComponentDidMount equivalent
  useEffect(() => {
    updateContent();
    const interval = setInterval(() => {
      updateContent();
    }, 10000);
    // ComponentWillUnmount equivalent
    return () => clearInterval(interval);
  }, [updateContent]);

  return (
    <Spin spinning={loading}>
      <Table columns={columns} dataSource={contacts} rowKey="pk" />
      <Modal
        title="Edit Contact"
        visible={editingContact !== null}
        footer={null}
        onCancel={handleCancel}
        destroyOnClose
      >
        <NewContactForm
          onFinish={handleOk}
          onCancel={handleCancel}
          contact={editingContact ? editingContact : undefined}
          edit={true}
        ></NewContactForm>
      </Modal>
    </Spin>
  );
}

export default ContactsTable;
