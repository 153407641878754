import Stream from "../models/Stream";

const API_RESOURCE = "streams/";

export class StreamsAPIHelper {
  public static async getAllAsync() {
    const response = await fetch(
      window._env_.REACT_APP_PCS_API_URL + API_RESOURCE
    );
    let stream_dtos = await (response.json() as Promise<Stream[]>).catch((e) => {
      return null;
    });
    if (stream_dtos === null) {
      return null;
    }
    return stream_dtos.map((dto) => {return Stream.from(dto);})
  }

  public static async getSingleAsync(id: string) {
    const response = await fetch(
      window._env_.REACT_APP_PCS_API_URL + API_RESOURCE + id + "/"
    );
    let stream_dto = await (response.json() as Promise<Stream>).catch(() => {
      return null;
    });
    if (stream_dto === null) {
      return null;
    }
    return Stream.from(stream_dto);
  }

  public static async createSingleAsync(stream: Stream) {
    const response = await fetch(
      window._env_.REACT_APP_PCS_API_URL + API_RESOURCE,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(stream),
      }
    );
    let alert_rule_dto = await (response.json() as Promise<Stream>).catch(
      () => {
        return null;
      }
    );
    if (alert_rule_dto === null) {
      return null;
    }
    return Stream.from(alert_rule_dto);
  }
}
