import React from "react"; // importing FunctionComponent
import { Layout } from "antd";
import AlertHistoryTable from "./subcomponents/AlertHistoryTable";

interface AlertasProps {}
interface AlertasState {}

class AlertHistory extends React.Component<AlertasProps, AlertasState> {
  render() {
    return (
      <Layout.Content
        className="content"
        style={{
          padding: 24,
          margin: 0,
          minHeight: 280,
        }}
      >
        <AlertHistoryTable streamId=""></AlertHistoryTable>
      </Layout.Content>
    );
  }
}

export default AlertHistory;
