import React from "react";
import { Card, Space, Button, Form, Input } from "antd";
import { PCSAlertRule } from "../../../../../../models/PCSAlertRule";
import { PCSAlerRuleAPIHelper } from "../../../../../../api-helpers/PCSAlertRuleAPIHelper";

interface NewRuleCardProps {
  streamId?: string;
  onRulesChanged?: () => void;
}

export function NewRuleCard(props: NewRuleCardProps) {
  const onFinish = (values: any) => {
    if (!props.streamId) {
      return;
    }
    let rule = new PCSAlertRule();
    rule.name = values["name"];
    rule.stream = props.streamId;
    rule.severity = "medium";
    PCSAlerRuleAPIHelper.createSingleAsync(rule).then(() => {
      if (props.onRulesChanged) {
        props.onRulesChanged();
      }
    });
  };

  return (
    <Card title={"Create New Rule"}>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFinish}
      >
        <Form.Item
          label="Rule Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please enter a valid name for the rule!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 8 }}>
          <Space>
            <Button type="primary" htmlType="submit">
              Create Rule
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Card>
  );
}

export default NewRuleCard;
