import { Card, Row, Col, Descriptions, Spin, Space } from "antd";
import Alert from "../../../../../models/Alert";
import { Link } from "react-router-dom";
import { StreamsAPIHelper } from "../../../../../api-helpers/StreamsAPIHelper";
import { useCallback, useEffect, useState } from "react";
import Stream from "../../../../../models/Stream";
import { severityToBadge, capitalizeFirstLetter } from "./utils";

interface AlertCardProps {
  alert: Alert;
}

function fixDate(dateTimeUTC: string): string {
  const DateUTC = new Date(dateTimeUTC);
  const argentinaTimeOffset = new Date().getTimezoneOffset();
  const dateTimeInArgentina = new Date(DateUTC.getTime() - argentinaTimeOffset * 60000);
  const formattedDateTime = `${dateTimeInArgentina.toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })}, ${dateTimeInArgentina.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  })}`;
  return formattedDateTime;
}

function AlertCard(props: AlertCardProps) {
  const [stream, setStream] = useState<Stream>(new Stream());
  const [loading, setLoading] = useState<boolean>(true);

  const updateContent = useCallback(() => {
    let stream_id = props.alert.stream;
    if (stream_id === undefined) {
      return;
    }
    StreamsAPIHelper.getSingleAsync(stream_id).then((stream) => {
      if (stream === null) {
        return;
      }
      setStream(stream);
      setLoading(false);
    });
  }, [props.alert.stream]);

  // ComponentDidMount equivalent
  useEffect(() => {
    updateContent();
    const interval = setInterval(() => {
      updateContent();
    }, 10000);
    // ComponentWillUnmount equivalent
    return () => clearInterval(interval);
  }, [updateContent]);

  if (!window._env_.REACT_APP_PCS_API_URL) {
    return <></>;
  }
  return (
    <Link to={"/alerts/" + props.alert._id}>
      <Spin spinning={loading}>
        <Card style={{ height: "100%" }} title={props.alert.rule + " Alert"}>
          <Row gutter={[8, 8]} justify="center" align="middle">
            <Col xl={6} lg={24}>
              <img
                src={
                  window._env_.REACT_APP_PCS_API_URL +
                  "media/results/alerts/" +
                  props.alert._id +
                  ".jpg"
                }
                alt={"Unable to load the live stream."}
                width={"90%"}
                style={{
                  maxHeight: "150px",
                }}
              ></img>
            </Col>
            <Col xl={18} lg={24}>
              <Descriptions
                layout={"vertical"}
                bordered
                column={3}
                size={"small"}
              >
                <Descriptions.Item label="Stream" span={1}>
                  {stream.name}
                </Descriptions.Item>
                <Descriptions.Item label="Timestamp" span={2}>
                  {fixDate(props.alert.timestamp)}
                </Descriptions.Item>
                <Descriptions.Item label="Severity">
                  <Space>
                    {severityToBadge(props.alert.severity)}
                    {capitalizeFirstLetter(props.alert.severity)}
                  </Space>
                </Descriptions.Item>
                <Descriptions.Item label="Condition">
                  {capitalizeFirstLetter(props.alert.condition)}
                </Descriptions.Item>
                <Descriptions.Item label="Detected">
                  {capitalizeFirstLetter(props.alert.value)}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
        </Card>
      </Spin>
    </Link>
  );
}

export default AlertCard;
