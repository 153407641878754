import { useCallback, useEffect, useState } from "react";
import { Table, Button, Spin, Breakpoint } from "antd";
import { Link } from "react-router-dom";
import { InfoCircleOutlined } from "@ant-design/icons";
import Stream from "../../../../../../models/Stream";
import { StreamsAPIHelper } from "../../../../../../api-helpers/StreamsAPIHelper";

const columns = [
  {
    title: "Nombre",
    key: "name",
    render: (item: Stream) => (
      <Link to={"/streams/" + item._id.toString()}>{item.name}</Link>
    ),
  },
  {
    title: "FPS",
    dataIndex: "fps",
    key: "fps",
    responsive: ["lg"] as Breakpoint[],
  },
  {
    title: "",
    render: (item: Stream) => (
      <Link to={"/streams/" + item._id.toString()}>
        {" "}
        <Button type="primary" shape="round" icon={<InfoCircleOutlined />}>
          Info
        </Button>
      </Link>
    ),
  },
];

interface StreamsTableProps {}

function StreamsTable(props: StreamsTableProps) {
  const [streams, setStreams] = useState<Array<Stream>>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const updateContent = useCallback(() => {
    StreamsAPIHelper.getAllAsync().then((streams) => {
      if (streams == null) {
        return;
      }
      console.log(streams);
      const sortedStreams = [...streams].sort((a, b) => a.name.localeCompare(b.name));
      setStreams(sortedStreams);
      setLoading(false);
    });
  }, []);

  // ComponentDidMount equivalent
  useEffect(() => {
    updateContent();
    const interval = setInterval(() => {
      updateContent();
    }, 10000);
    // ComponentWillUnmount equivalent
    return () => clearInterval(interval);
  }, [updateContent]);

  return (
    <Spin spinning={loading}>
      <Table columns={columns} dataSource={streams} rowKey="pk" />
    </Spin>
  );
}

export default StreamsTable;
