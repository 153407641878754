import Contact from "../models/Contact";

const API_RESOURCE = "contacts/";

export class ContactsAPIHelper {
  public static async getAllAsync(query_string: string = "") {
    const response = await fetch(
      window._env_.REACT_APP_PCS_API_URL + API_RESOURCE + "?" + query_string
    );
    let contact_dtos = await (
      response.json() as Promise<Contact[]>
    ).catch((e) => {
      console.log(e);
      return null;
    });
    if (contact_dtos === null) {
      return null;
    }
    return contact_dtos.map((dto) => {
      return Contact.from(dto);
    });
  }

  public static async getSingleAsync(id: number | string) {
    const response = await fetch(
      window._env_.REACT_APP_PCS_API_URL + API_RESOURCE + id + "/"
    );
    let contact_dto = await (response.json() as Promise<Contact>).catch(
      () => {
        return null;
      }
    );
    if (contact_dto === null) {
      return null;
    }
    return Contact.from(contact_dto);
  }

  public static async updateSingleAsync(contact: Contact) {
    const response = await fetch(
      window._env_.REACT_APP_PCS_API_URL + API_RESOURCE + contact._id + "/",
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(contact),
      }
    );
    let contact_dto = await (response.json() as Promise<Contact>).catch(
      () => {
        return null;
      }
    );
    if (contact_dto === null) {
      return null;
    }
    return Contact.from(contact_dto);
  }

  public static async createSingleAsync(contact: Contact) {
    const response = await fetch(
      window._env_.REACT_APP_PCS_API_URL + API_RESOURCE,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(contact),
      }
    );
    let contact_dto = await (response.json() as Promise<Contact>).catch(
      () => {
        return null;
      }
    );
    if (contact_dto === null) {
      return null;
    }
    return Contact.from(contact_dto);
  }

  public static async deleteOneAsync(id: string) {
    await fetch(
      window._env_.REACT_APP_PCS_API_URL + API_RESOURCE + id + "/",
      { method: "DELETE" }
    );
  }
}
