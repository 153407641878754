import { Spin } from "antd";
import { useEffect, useMemo, useState } from "react"; // importing FunctionComponent

interface FrameComponentProps {
  path: string;
}

// http://localhost:9000/1/last_frame.jpg

function FrameComponent(props: FrameComponentProps) {
  const [fetching, setFetching] = useState<boolean>(false);
  const [imageBlob, setImageBlob] = useState<Blob | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [imageObjectURL, setImageObjectURL] = useState<string>("");

  // ComponentDidMount equivalent
  useEffect(() => {
    const interval = setInterval(() => {
      if (fetching) {
        return;
      }
      setFetching(true);
      fetch(props.path)
        .then((response) => {
          if (response.status !== 200) {
            throw new Error("Unable to fetch the live stream.");
          }
          return response.blob();
        })
        .then((imageBlob) => {
          if (imageBlob === null || imageBlob.size === 0 || imageBlob.type !== "image/jpg") {
            throw new Error("Unable to fetch the live stream.");
          }
          // Validate the image, the size should be greater than 0
          setImageBlob(imageBlob);
          setImageObjectURL(URL.createObjectURL(imageBlob));
          setFetching(false);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setFetching(false);
          setLoading(false);
        });
    }, 200);
    // ComponentWillUnmount equivalent
    return () => clearInterval(interval);
  });

  return (
    <Spin spinning={loading}>
      {loading ? (
        <></>
      ) : (
        <img
          src={imageObjectURL}
          alt={"Unable to load the live stream."}
          width={"100%"}
        />
      )}
    </Spin>
  );
}

export default FrameComponent;
