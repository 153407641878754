import React, { useEffect, useState } from "react";
import { Button, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import Contact from "../../../../../../models/Contact";
import { ContactsAPIHelper } from "../../../../../../api-helpers/ContactsAPIHelper";

const columns: ColumnsType<Contact> = [
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Email",
    dataIndex: "email",
  },
  {
    title: "Phone Number",
    dataIndex: "phone",
  },
];

interface SelectContactsFormProps {
  onAccept: (contacts: Array<Contact>) => void;
  existingContacts: Array<Contact>;
}

function SelectContactsForm(props: SelectContactsFormProps) {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedContacts, setSelectedContacts] = useState<Array<Contact>>([]);
  const [loading, setLoading] = useState(false);
  const [contacts, setContacts] = useState<Array<Contact>>([]);

  // ComponentDidMount equivalent
  useEffect(() => {
    ContactsAPIHelper.getAllAsync().then((contacts) => {
      if (contacts == null) {
        return;
      }
      // Remove existing contacts from the list
      contacts = contacts.filter(
        (contact) =>
          !props.existingContacts.some(
            (existingContact) => existingContact._id === contact._id
          )
      );
      console.log(contacts);
      setContacts(contacts);
      setLoading(false);
    });
  }, [props.existingContacts]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedContacts(
      contacts.filter((contact) => newSelectedRowKeys.includes(contact._id))
    );
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <div>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={contacts}
        rowKey={(contact) => contact._id}
      />
      <div style={{ marginBottom: 16 }}>
        <Button
          type="primary"
          onClick={() => {
            props.onAccept(selectedContacts);
          }}
          loading={loading}
        >
          Accept
        </Button>
      </div>
    </div>
  );
}

export default SelectContactsForm;
