import { Descriptions, Card, Tag, Space } from "antd";
import Stream from "../../../../../../models/Stream";

interface HeaderPCServiceProps {
  stream: Stream;
}

export function HeaderPCService(props: HeaderPCServiceProps) {
  var status: boolean = true;
  return (
    <Card title="AI Service Details" style={{ width: "100%", height: "100%" }}>
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <Descriptions layout={"horizontal"} bordered>
          <Descriptions.Item label="Name" span={3}>
            {"Human detection and PPE."}
          </Descriptions.Item>
          <Descriptions.Item label="Input Stream" span={3}>
            {props.stream.name}
          </Descriptions.Item>
          <Descriptions.Item label="Status" span={3}>
            <Tag color={status ? "green" : "volcano"}>
              {status ? "Active" : "Inactive"}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item label="FPS" span={3}>
            {15}
          </Descriptions.Item>
        </Descriptions>
      </Space>
    </Card>
  );
}

export default HeaderPCService;
