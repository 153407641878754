import { Button, Card, Form, Input, Layout, Row } from "antd";
import { LoginAPIHelper } from "../../api-helpers/LoginAPIHelper";

interface LoginPageProps {
  onSuccess?: (token: string) => void;
}

function LoginPage(props: LoginPageProps) {
  const onFinish = (values: any) => {
    LoginAPIHelper.login(values["username"], values["password"]).then(
      (token) => {
        if (token == null) {
          return;
        }
        if (props.onSuccess) {
          props.onSuccess(token);
        }
      }
    );
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Layout
      style={{
        height: "100vh",
        width: "100vw",
        margin: "auto",
        display: "table",
        textAlign: "center",
      }}
    >
      <Layout.Content
        style={{
          height: "100%",
          width: "100%",
          margin: "auto",
          display: "table",
          textAlign: "center",
          backgroundImage: "linear-gradient(#0066ff, #270a9e)",
        }}
      >
        <Row
          justify="space-around"
          align="middle"
          style={{
            height: "100%",
          }}
        >
          <Card title="AlgoLabs AI Central" style={{ borderRadius: "10px" }}>
            <Form
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Username"
                name="username"
                rules={[
                  { required: true, message: "Please input your username!" },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Row>
      </Layout.Content>
    </Layout>
  );
}

export default LoginPage;
