import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useCallback, useEffect, useState } from "react";
import { StatsAPIHelper } from "../../../../../api-helpers/StatsAPIHelper";
import { STYLED_COLORS } from "../../../../../common/constants";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface DailyAlertTypesEntry {
  _id: { day: string; type: string };
  count: number;
}

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
  },
};

interface AlertsDailyDistributionProps {
  startDate: Date;
  endDate: Date;
}

const ALERT_TYPES = [
  "hat",
  "vest",
  "count_lt",
  "count_gt",
  "clothes_color"
];
const ALERT_COLORS = ["yellow", "blue", "green", "red", "orange"];

export function AlertsDailyDistribution(props: AlertsDailyDistributionProps) {
  const [stats, setStats] = useState<Array<DailyAlertTypesEntry>>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const updateContent = useCallback(() => {
    let query_string =
      "timestamp__gte=" +
      props.startDate.toISOString() +
      "&" +
      "timestamp__lte=" +
      props.endDate.toISOString();
    StatsAPIHelper.getDailyAlertTypes(query_string).then((stats) => {
      if (stats == null) {
        return;
      }
      console.log(stats as Array<DailyAlertTypesEntry>);
      setStats(stats as Array<DailyAlertTypesEntry>);
      setLoading(false);
    });
  }, [props]);

  // ComponentDidMount equivalent
  useEffect(() => {
    updateContent();
    const interval = setInterval(() => {
      updateContent();
    }, 10000);
    // ComponentWillUnmount equivalent
    return () => clearInterval(interval);
  }, [updateContent]);

  const MAX_DAYS = 7;

  var days = [];

  for (let i = 0; i < MAX_DAYS; i++) {
    var ms = new Date().getTime() - i * 86400000;
    var date = new Date(ms);
    var userTimezoneOffset = date.getTimezoneOffset() * 60000;
    date = new Date(date.getTime() - userTimezoneOffset);
    days.push(
      date.toLocaleDateString(undefined, { day: "2-digit", month: "2-digit" })
    );
  }

  const labels = days.reverse();

  const formated_labels = labels.map((l) => l.slice(0, 5));
  console.log(labels);
  console.log(formated_labels);
  let data = {
    labels: formated_labels,
    datasets: ALERT_TYPES.map((type) => ({
      label: type,
      data: new Array<number>(MAX_DAYS).fill(0),
      borderColor: STYLED_COLORS[ALERT_COLORS[ALERT_TYPES.indexOf(type)]],
      backgroundColor: STYLED_COLORS[ALERT_COLORS[ALERT_TYPES.indexOf(type)]],
    })),
  };

  if (loading) {
    return <></>;
  }

  stats.forEach((entry) => {
    let dataset_index = -1;
    ALERT_TYPES.forEach((type) => {
        if (entry._id.type.includes(type)) {
            dataset_index= ALERT_TYPES.indexOf(type)
        }
    })
    if (dataset_index === -1) {
      return;
    }
    console.log(dataset_index);
    var parts = entry._id.day.split("-");
    let day_index = formated_labels.indexOf(parts[1] + "/" + parts[2]);
    data.datasets[dataset_index].data[day_index] += entry.count;
  });

  return <Bar options={options} data={data}  height={"200px"}/>;
}
