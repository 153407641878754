import { Card, Col, Input, Row, Spin } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { StreamsAPIHelper } from "../../../../api-helpers/StreamsAPIHelper";
import Stream from "../../../../models/Stream";
import FrameComponent from "../../../helpers/FrameComponent";
import { SearchOutlined } from "@ant-design/icons";

interface ViewAllProps {}

function ViewAll(props: ViewAllProps) {
  const [streams, setStreams] = useState<Array<Stream>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [search, setSearch] = useState<string>("");

  const updateContent = useCallback(() => {
    StreamsAPIHelper.getAllAsync().then((streams) => {
      if (streams === null) {
        return;
      }
      // Sort by name
      streams.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      setStreams(streams);
      setLoading(false);
    });
  }, []);

  // ComponentDidMount equivalent
  useEffect(() => {
    updateContent();
    const interval = setInterval(() => {
      updateContent();
    }, 10000);
    // ComponentWillUnmount equivalent
    return () => clearInterval(interval);
  }, [updateContent]);

  const filtered_streams = useMemo(() => {
    return streams.filter((s) => {
      return s.name.toLowerCase().includes(search.toLowerCase());
    });
  }, [search, streams]);

  if (!window._env_.REACT_APP_PCS_API_URL) {
    return <></>;
  }

  return (
    <Spin spinning={loading}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card title={"Live View"}>
            <Input
              placeholder="Filter by name"
              prefix={<SearchOutlined />}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </Card>
        </Col>
        {filtered_streams.map((s) => {
          let path =
            window._env_.REACT_APP_PCS_API_URL + "frames/" + s._id + "/";
          return (
            <Col xl={12} lg={24} flex="auto">
              <Link to={"/streams/" + s._id}>
                <Card title={s.name}>
                  <FrameComponent path={path} />
                </Card>
              </Link>
            </Col>
          );
        })}
      </Row>
    </Spin>
  );
}

export default ViewAll;
