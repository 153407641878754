const API_RESOURCE = "stats/";

export class StatsAPIHelper {
  public static async getDailyHatUsage(query_string: string = "") {
    const response = await fetch(
      window._env_.REACT_APP_PCS_API_URL +
        API_RESOURCE +
        "dailyhatusage/?" +
        query_string
    );
    let stats_dto = await response.json().catch((e) => {
      console.log(e);
      return null;
    });
    if (stats_dto === null) {
      return null;
    }
    return stats_dto;
  }

  public static async getDailyHatColorUsage(query_string: string = "") {
    const response = await fetch(
      window._env_.REACT_APP_PCS_API_URL +
        API_RESOURCE +
        "dailyhatcolors/?" +
        query_string
    );
    let stats_dto = await response.json().catch((e) => {
      console.log(e);
      return null;
    });
    if (stats_dto === null) {
      return null;
    }
    return stats_dto;
  }

  public static async getDailyAlertTypes(query_string: string = "") {
    const response = await fetch(
      window._env_.REACT_APP_PCS_API_URL +
        API_RESOURCE +
        "dailyalerttypes/?" +
        query_string
    );
    let stats_dto = await response.json().catch((e) => {
      console.log(e);
      return null;
    });
    if (stats_dto === null) {
      return null;
    }
    return stats_dto;
  }

  public static async getCurrentDetectionCount(query_string: string = "") {
    const response = await fetch(
      window._env_.REACT_APP_PCS_API_URL +
        API_RESOURCE +
        "currentdetectioncount/?" +
        query_string
    );
    let stats_dto = await response.json().catch((e) => {
      console.log(e);
      return null;
    });
    if (stats_dto === null) {
      return null;
    }
    return stats_dto;
  }

  public static async getAlertsByStream(query_string: string = "") {
    const response = await fetch(
      window._env_.REACT_APP_PCS_API_URL +
        API_RESOURCE +
        "alertsbystream/?" +
        query_string
    );
    let stats_dto = await response.json().catch((e) => {
      console.log(e);
      return null;
    });
    if (stats_dto === null) {
      return null;
    }
    return stats_dto;
  }
}
