import { PCSAlertRule } from "../models/PCSAlertRule";

const API_RESOURCE = "pcsalertrules/";

export class PCSAlerRuleAPIHelper {
  public static async getAllAsync(query_string: string = "") {
    const response = await fetch(
      window._env_.REACT_APP_PCS_API_URL + API_RESOURCE + "?" + query_string
    );
    let alert_rule_dtos = await (
      response.json() as Promise<PCSAlertRule[]>
    ).catch((e) => {
      console.log(e);
      return null;
    });
    if (alert_rule_dtos === null) {
      return null;
    }
    return alert_rule_dtos.map((dto) => {
      return PCSAlertRule.from(dto);
    });
  }

  public static async getSingleAsync(id: number) {
    const response = await fetch(
      window._env_.REACT_APP_PCS_API_URL + API_RESOURCE + id + "/"
    );
    let alert_rule_dto = await (response.json() as Promise<PCSAlertRule>).catch(
      () => {
        return null;
      }
    );
    if (alert_rule_dto === null) {
      return null;
    }
    return PCSAlertRule.from(alert_rule_dto);
  }

  public static async updateSingleAsync(rule: PCSAlertRule) {
    console.log(JSON.stringify(rule))
    const response = await fetch(
      window._env_.REACT_APP_PCS_API_URL + API_RESOURCE + rule._id + "/",
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(rule),
      }
    );
    let alert_rule_dto = await (response.json() as Promise<PCSAlertRule>).catch(
      () => {
        return null;
      }
    );
    if (alert_rule_dto === null) {
      return null;
    }
    return PCSAlertRule.from(alert_rule_dto);
  }

  public static async createSingleAsync(rule: PCSAlertRule) {
    const response = await fetch(
      window._env_.REACT_APP_PCS_API_URL + API_RESOURCE,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(rule),
      }
    );
    let alert_rule_dto = await (response.json() as Promise<PCSAlertRule>).catch(
      () => {
        return null;
      }
    );
    if (alert_rule_dto === null) {
      return null;
    }
    return PCSAlertRule.from(alert_rule_dto);
  }

  public static async deleteOneAsync(id: string) {
    await fetch(window._env_.REACT_APP_PCS_API_URL + API_RESOURCE + id + "/", {
      method: "DELETE",
    });
  }
}
