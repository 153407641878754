import { useCallback, useEffect, useState } from "react";
import { Card, Row, Col, Divider, Spin } from "antd";
import FrameComponent from "../../../../helpers/FrameComponent";
import { useParams } from "react-router-dom";
import Stream from "../../../../../models/Stream";
import { StreamsAPIHelper } from "../../../../../api-helpers/StreamsAPIHelper";
import HeaderPCService from "./subcomponents/HeaderPCService";
import RuleConfig from "./subcomponents/RuleConfig";
import { PCSAlertRule } from "../../../../../models/PCSAlertRule";
import { PCSAlerRuleAPIHelper } from "../../../../../api-helpers/PCSAlertRuleAPIHelper";
import NewRuleCard from "./subcomponents/NewRuleCard";

interface ServiceConfigProps {}

function ServiceConfig(props: ServiceConfigProps) {
  console.log(window._env_.REACT_APP_PCS_API_URL)
  const [stream, setStream] = useState<Stream>(new Stream());
  const [loading, setLoading] = useState<boolean>(true);
  const [rules, setRules] = useState<Array<PCSAlertRule>>([]);

  let { streamId } = useParams();

  console.log(streamId);
  const updateContent = useCallback(() => {
    if (streamId === undefined) {
      return;
    }
    StreamsAPIHelper.getSingleAsync(streamId).then((stream) => {
      if (stream === null) {
        return;
      }
      setStream(stream);
      setLoading(false);
    });
    PCSAlerRuleAPIHelper.getAllAsync("stream=" + streamId).then(
      (rules) => {
        if (rules === null) {
          return;
        }
        console.log("setting rules:");
        console.log(rules);
        setRules(rules);
      }
    );
  }, [streamId]);

  // ComponentDidMount equivalent
  useEffect(() => {
    updateContent();
  }, [updateContent]);

  let path = "";
  if (window._env_.REACT_APP_PCS_API_URL) {
    path =
      window._env_.REACT_APP_PCS_API_URL + "frames/" + streamId;
  }
  return (
    <Spin spinning={loading}>
      <Row gutter={[16, 16]}>
        <Col xl={8} lg={24} flex="auto">
          <HeaderPCService stream={stream} />
        </Col>
        <Col xl={16} lg={24}>
          <Card title="Live View" style={{ width: "100%", height: "100%" }}>
            <Spin spinning={loading}>
              <FrameComponent path={path} />
            </Spin>
          </Card>
        </Col>
      </Row>
      <Divider orientation="left">AI Rules</Divider>
      <Row gutter={[16, 16]}>
        {rules.map((r) => {
          return (
            <Col span={24}>
              <RuleConfig rule={r} streamId={streamId} onRulesChanged={updateContent} />
            </Col>
          );
        })}
        <Col span={24}>
          <NewRuleCard onRulesChanged={updateContent} streamId={streamId} />
        </Col>
      </Row>
    </Spin>
  );
}

export default ServiceConfig;
