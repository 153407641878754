import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useCallback, useEffect, useState } from "react";
import { StatsAPIHelper } from "../../../../../api-helpers/StatsAPIHelper";
import { STYLED_COLORS } from "../../../../../common/constants";
import Stream from "../../../../../models/Stream";
import { StreamsAPIHelper } from "../../../../../api-helpers/StreamsAPIHelper";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface DetectionsByStreamEntry {
  _id: { stream: string };
  count: number;
}

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
  },
};

export function DetectionsByStream() {
  const [stats, setStats] = useState<Array<DetectionsByStreamEntry>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [streams, setStreams] = useState<Array<Stream>>([]);

  const updateContent = useCallback(() => {
    StatsAPIHelper.getCurrentDetectionCount().then((stats) => {
      if (stats === null) {
        return;
      }
      console.log(stats as Array<DetectionsByStreamEntry>);
      setStats(stats as Array<DetectionsByStreamEntry>);
    });
    StreamsAPIHelper.getAllAsync().then((streams) => {
      if (streams === null) {
        return;
      }
      console.log(streams);
      setStreams(streams);
      setLoading(false);
    });
  }, []);

  // ComponentDidMount equivalent
  useEffect(() => {
    updateContent();
    const interval = setInterval(() => {
      updateContent();
    }, 1000);
    // ComponentWillUnmount equivalent
    return () => clearInterval(interval);
  }, [updateContent]);

  if (loading) {
    return <></>;
  }

  const labels = streams.map((s) => s.name);

  let data = {
    labels: labels,
    datasets: [
      {
        label: "Detections",
        data: new Array<number>(labels.length).fill(0),
        borderColor: STYLED_COLORS["green"],
        backgroundColor: STYLED_COLORS["green"],
      },
    ],
  };

  stats.forEach((entry) => {
    let x_index = -1;
    streams.forEach((stream) => {
      if (entry._id.stream === stream.name) {
        x_index = streams.indexOf(stream);
      }
    });
    if (x_index === -1) {
      return;
    }
    data.datasets[0].data[x_index] += entry.count;
  });

  return <Bar options={options} data={data} height={"200px"} />;
}
