import "./App.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import StreamsList from "./components/layout/contents/streams/list/StreamsList";
import Ayuda from "./components/layout/contents/Ayuda";
import StreamDetails from "./components/layout/contents/streams/details/StreamDetails";
import AppLayout from "./components/layout/AppLayout";
import ServiceConfig from "./components/layout/contents/services/configure/ServiceConfig";
import ViewAll from "./components/layout/contents/live_view/ViewAll";
import AlertDetails from "./components/layout/contents/alerts/AlertDetails";
import LoginPage from "./components/login/LoginPage";
import { useEffect, useState } from "react";
import ContactList from "./components/layout/contents/contacts/ContactList";
import AlertFeed from "./components/layout/contents/alerts/AlertFeed";
import AlertHistory from "./components/layout/contents/alerts/AlertHistory";
import Dashboard from "./components/layout/contents/dashboard/Dashboard";

function App() {
  const [accessToken, setAccessToken] = useState<string | undefined>("");
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (accessToken) {
      sessionStorage.setItem("token", accessToken);
    }
  }, [accessToken]);

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (token) {
      setAccessToken(token);
    }
    setLoading(false);
  }, []);

  if (loading) {
    return <></>;
  }

  if (!accessToken) {
    return <LoginPage onSuccess={setAccessToken} />;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="*"
          element={
            <AppLayout>
              <Routes>
                <Route path="*" element={<Dashboard />} />
                <Route path="alerts">
                  <Route path="feed" element={<AlertFeed />} />
                  <Route path="history" element={<AlertHistory />} />
                  <Route path=":alertId" element={<AlertDetails />} />
                </Route>
                <Route path="ayuda" element={<Ayuda />} />
                <Route path="streams">
                  <Route path=":streamId" element={<StreamDetails />} />
                  <Route path="" element={<StreamsList />} />
                </Route>
                <Route path="services">
                  <Route path="config/:streamId" element={<ServiceConfig />} />
                </Route>
                <Route path="viewall" element={<ViewAll />} />
                <Route path="contacts">
                  <Route path="" element={<ContactList />} />
                </Route>
              </Routes>
            </AppLayout>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
