import { useCallback, useEffect, useState } from "react";
import { HeaderStream } from "./subcomponents/HeaderStream";
import { Space, Card, Row, Col, Divider, Spin } from "antd";
import StreamAIServicesList from "./subcomponents/StreamAIServicesList";
import FrameComponent from "../../../../helpers/FrameComponent";
import { useParams } from "react-router-dom";
import Stream from "../../../../../models/Stream";
import { StreamsAPIHelper } from "../../../../../api-helpers/StreamsAPIHelper";
import AlertsFeed from "../../alerts/subcomponents/AlertsFeedSubcomponent";

interface StreamDetailsProps {}

function StreamDetails(props: StreamDetailsProps) {
  const [stream, setStream] = useState<Stream>(new Stream());
  const [loading, setLoading] = useState<boolean>(true);

  let { streamId } = useParams();

  const updateContent = useCallback(() => {
    if (streamId === undefined) {
      return;
    }
    StreamsAPIHelper.getSingleAsync(streamId).then((stream) => {
      if (stream == null) {
        return;
      }
      setStream(stream);
      setLoading(false);
    });
  }, [streamId]);

  // ComponentDidMount equivalent
  useEffect(() => {
    updateContent();
    const interval = setInterval(() => {
      updateContent();
    }, 10000);
    // ComponentWillUnmount equivalent
    return () => clearInterval(interval);
  }, [updateContent]);

  let path = "";
  if (window._env_.REACT_APP_PCS_API_URL) {
    path =
      window._env_.REACT_APP_PCS_API_URL + "frames/" + streamId;
  }
  return (
    <Spin spinning={loading}>
      <Row gutter={[16, 16]}>
        <Col xl={8} lg={24} flex="auto">
          <HeaderStream stream={stream} />
        </Col>
        <Col xl={16} lg={24}>
          <Card title="Live View" style={{ width: "100%", height: "100%" }}>
            <Spin spinning={loading}>
              <FrameComponent path={path} />
            </Spin>
          </Card>
        </Col>
      </Row>
      <Divider orientation="left">AI Services</Divider>
      <Row gutter={[16, 16]}>
        <Card title="" style={{ width: "100%" }}>
          <Space direction="vertical" size="middle" style={{ display: "flex" }}>
            <StreamAIServicesList stream={stream} />
          </Space>
        </Card>
      </Row>
      <Divider orientation="left">Alerts History</Divider>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          {streamId ? <AlertsFeed streamId={streamId}></AlertsFeed> : <></>}
        </Col>
      </Row>
    </Spin>
  );
}

export default StreamDetails;
