export default class Contact {
    _id: string = "";
    idx: number = 0;
    name: string = "";
    email: string = "";
    phone: string = "";
  
    public static from(json: any) {
      return Object.assign(new Contact(), json) as Contact;
    }
  }
  