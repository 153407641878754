import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Space,
  Button,
  Form,
  Input,
  Checkbox,
  InputNumber,
  TimePicker,
  Popconfirm,
  Modal,
  Select,
  Badge,
  Divider,
  Collapse,
  Row,
  Col,
  Spin,
  List,
  Card,
  ColorPicker,
} from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import {
  PCSAlertRule,
  PCSAlertCondition,
} from "../../../../../../models/PCSAlertRule";
import dayjs from "dayjs";
import { PCSAlerRuleAPIHelper } from "../../../../../../api-helpers/PCSAlertRuleAPIHelper";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import NewConditionModal from "./NewConditionModal";
import ConditionConfig from "./ConditionConfig";
import SelectContactsForm from "./SelectContactsForm";
import Contact from "../../../../../../models/Contact";
import { ContactsAPIHelper } from "../../../../../../api-helpers/ContactsAPIHelper";
import SelectRoiForm, { Point } from "./SelectAOIForm";

const { Option } = Select;
const { Panel } = Collapse;

interface RuleConfigProps {
  rule: PCSAlertRule;
  streamId: string | undefined;
  onRulesChanged?: () => void;
}

const CONDITION_TYPES = [
  "hat",
  "vest",
  "clothes_color",
  "count_lt",
  "count_gt",
];

const CONDITION_TYPES_LABELS: { [name: string]: string } = {
  hat: "Hard hat",
  vest: "Reflective vest",
  clothes_color: "Clothes color",
  count_lt: "Worker count less than",
  count_gt: "Worker count greater than",
};

const DAYS_OF_THE_WEEK = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

function formatLabel(label: string) {
  let spaced = label.replace("_", " ");
  return spaced.charAt(0).toUpperCase() + spaced.slice(1);
}

export function RuleConfig(props: RuleConfigProps) {
  const [loading, setLoading] = useState<boolean>(true);
  const [enableSave, setEnableSave] = useState<boolean>(false);
  const [showTriggerModal, setShowTriggerModal] = useState<boolean>(false);
  const [showFilterModal, setShowFilterModal] = useState<boolean>(false);
  const [showContactsModal, setShowContactsModal] = useState<boolean>(false);
  const [showRoiModal, setShowRoiModal] = useState<boolean>(false);
  const [triggers, setTriggers] = useState<PCSAlertCondition[]>(
    props.rule.triggers
  );
  const [filters, setFilters] = useState<PCSAlertCondition[]>(
    props.rule.filters
  );
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [roiPoints, setRoiPoints] = useState<Point[]>(
    props.rule.aoi.map((p) => {
      return { x: p[0], y: p[1] };
    })
  );

  const unusedConditionTypes = useMemo(() => {
    return CONDITION_TYPES.filter(
      (type) =>
        !filters.find((c) => c.type === type) &&
        !triggers.find((c) => c.type === type)
    );
  }, [filters, triggers]);

  // Fetch contacts function
  const fetchContacts = useCallback(async () => {
    console.log("Fetching contacts");
    let contacts: Contact[] = [];
    // Iterate over all the contacts
    for (let index = 0; index < props.rule.recipients.length; index++) {
      const contactId = props.rule.recipients[index];
      // Fetch the contact
      const contact = await ContactsAPIHelper.getSingleAsync(contactId);
      // Add the contact to the list
      if (contact) {
        contacts.push(contact);
      }
    }
    // Set the contacts
    setContacts(contacts);
  }, [props.rule.recipients]);

  // ComponentDidMount equivalent
  useEffect(() => {
    fetchContacts().then(() => {
      setLoading(false);
    });
  }, [fetchContacts]);

  const deleteContact = (contact: Contact) => {
    // Remove the contact from the list
    let newContacts = contacts.filter((c) => c._id !== contact._id);
    setContacts(newContacts);
    setEnableSave(true);
  };

  const onNewFilter = (type: string) => {
    setShowFilterModal(false);
    let newCondition = {
      type: type,
      value: null,
    };
    // Make sure we don't add the same condition twice, including also triggers
    if (
      filters.find((c) => c.type === type) ||
      triggers.find((c) => c.type === type)
    ) {
      Modal.error({
        title: "Error",
        content: `Condition ${formatLabel(type)} already exists.`,
      });
      return;
    }
    setFilters([...filters, newCondition]);
    setEnableSave(true);
  };

  const removeFilter = (filter: PCSAlertCondition) => {
    let newFilters = filters.filter((f) => f.type !== filter.type);
    setFilters(newFilters);
    setEnableSave(true);
  };

  const onNewTrigger = (type: string) => {
    setShowTriggerModal(false);
    let newCondition = {
      type: type,
      value: null,
    };
    // Make sure we don't add the same condition twice, including also triggers
    if (
      filters.find((c) => c.type === type) ||
      triggers.find((c) => c.type === type)
    ) {
      Modal.error({
        title: "Error",
        content: `Condition ${formatLabel(type)} already exists.`,
      });
      return;
    }
    setTriggers([...triggers, newCondition]);
    setEnableSave(true);
  };

  const removeTrigger = (trigger: PCSAlertCondition) => {
    let newTriggers = triggers.filter((t) => t.type !== trigger.type);
    setTriggers(newTriggers);
    setEnableSave(true);
  };

  const onFinish = (values: any) => {
    props.rule.name = values["name"];
    props.rule.active_days = values["active_days"].map((s: string) =>
      DAYS_OF_THE_WEEK.indexOf(s)
    );
    props.rule.start_time = values["active_hours"][0].format("HH:mm:ss");
    props.rule.stop_time = values["active_hours"][1].format("HH:mm:ss");
    props.rule.severity = values["severity"];
    props.rule.triggers = triggers;
    props.rule.filters = filters;
    props.rule.recipients = contacts.map((c) => c._id);

    if (roiPoints.length > 0) {
      props.rule.aoi = roiPoints.map((p) => [p.x, p.y]);
    }

    if (
      values["aoi_color"] !== undefined &&
      values["aoi_color"].metaColor !== undefined
    ) {
      props.rule.aoi_color = [
        parseInt(values["aoi_color"].metaColor.r),
        parseInt(values["aoi_color"].metaColor.g),
        parseInt(values["aoi_color"].metaColor.b),
      ];
    }

    PCSAlerRuleAPIHelper.updateSingleAsync(props.rule).then((value) => {
      if (value === null) {
        Modal.error({
          title: "Error",
          content: `Failed to update Rule.`,
        });
      }
      if (props.onRulesChanged) {
        props.onRulesChanged();
      }
      Modal.success({
        title: "Success",
        content: `Rule updated succesfully.`,
      });
      setEnableSave(false);
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const onDeleteRule = () => {
    PCSAlerRuleAPIHelper.deleteOneAsync(props.rule._id).then(() => {
      if (props.onRulesChanged) {
        props.onRulesChanged();
      }
    });
  };

  const onValuesChange = (changedValues: any, allValues: any) => {
    setEnableSave(true);
  };

  return (
    <Collapse defaultActiveKey={[""]}>
      <Panel header={props.rule.name} key="1">
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 14 }}
          initialValues={{
            name: props.rule.name,
            severity: props.rule.severity,
            active_days: props.rule.active_days.map((d) => DAYS_OF_THE_WEEK[d]),
            active_hours: [props.rule.start_time, props.rule.stop_time].map(
              (s) => dayjs(s, "HH:mm:ss")
            ),
            min_delay_secs: props.rule.min_delay_secs,
            cooldown_secs: props.rule.cooldown_secs,
            aoi_color: {
              r: props.rule.aoi_color[0],
              g: props.rule.aoi_color[1],
              b: props.rule.aoi_color[2],
            },
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={onValuesChange}
          autoComplete="off"
          size={"small" as SizeType}
        >
          <Divider orientation="left">Config</Divider>
          <Form.Item
            label="Rule Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please enter a valid name for the rule!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Severity" name="severity" valuePropName="value">
            <Select defaultValue="medium">
              <Option value="low">
                <Badge status="success" />
                Low
              </Option>
              <Option value="medium">
                <Badge status="warning" />
                Medium
              </Option>
              <Option value="high">
                <Badge status="error" />
                High
              </Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Active Days"
            name="active_days"
            valuePropName="value"
          >
            <Checkbox.Group options={DAYS_OF_THE_WEEK} />
          </Form.Item>

          <Form.Item
            label="Active Hours"
            name="active_hours"
            valuePropName="value"
          >
            <TimePicker.RangePicker />
          </Form.Item>
          <Form.Item
            label="Tolerance time [seconds]"
            name="min_delay_secs"
            rules={[
              {
                required: true,
                message: "Please enter a valid tolerance time in seconds!",
              },
            ]}
          >
            <InputNumber min={0} />
          </Form.Item>
          <Form.Item
            label="Cooldown [seconds]"
            name="cooldown_secs"
            rules={[
              {
                required: true,
                message: "Please enter a valid cooldown time in seconds!",
              },
            ]}
          >
            <InputNumber min={0} />
          </Form.Item>
          <Divider orientation="left">
            <Space>
              <Button
                icon={<PlusCircleOutlined />}
                type="primary"
                shape="round"
                onClick={() => setShowFilterModal(true)}
              ></Button>
              Filters{" "}
            </Space>
          </Divider>
          {filters.map((filter, index) => (
            <Form.Item
              label={
                <Space>
                  <Button
                    type="primary"
                    shape="circle"
                    size="small"
                    danger
                    onClick={() => {
                      removeFilter(filter);
                    }}
                  >
                    -
                  </Button>
                  {CONDITION_TYPES_LABELS[filter.type]}
                </Space>
              }
            >
              <ConditionConfig
                key={index}
                condition={filter}
                onValueChanged={(value: any) => {
                  filters[index].value = value;
                  setFilters([...filters]);
                  setEnableSave(true);
                }}
              />
            </Form.Item>
          ))}
          <Divider orientation="left">
            {" "}
            <Space>
              <Button
                icon={<PlusCircleOutlined />}
                type="primary"
                shape="round"
                onClick={() => setShowTriggerModal(true)}
              ></Button>
              Triggers{" "}
            </Space>{" "}
          </Divider>
          {triggers.map((trigger, index) => (
            <Form.Item
              label={
                <Space>
                  <Button
                    type="primary"
                    shape="circle"
                    size="small"
                    danger
                    onClick={() => {
                      removeTrigger(trigger);
                    }}
                  >
                    -
                  </Button>
                  {CONDITION_TYPES_LABELS[trigger.type]}
                </Space>
              }
            >
              <ConditionConfig
                key={index}
                condition={trigger}
                onValueChanged={(value: any) => {
                  triggers[index].value = value;
                  setTriggers([...triggers]);
                  setEnableSave(true);
                }}
              />
            </Form.Item>
          ))}
          <Divider orientation="left">
            {" "}
            <Space>
              <Button
                icon={<PlusCircleOutlined />}
                type="primary"
                shape="round"
                onClick={() => setShowContactsModal(true)}
              ></Button>
              Recipients{" "}
            </Space>{" "}
          </Divider>
          <Spin spinning={loading}>
            {contacts.map((contact) => (
              <Card>
                <Row align="middle" justify="center">
                  <Col span={4}>
                    <Button
                      type="primary"
                      danger
                      onClick={() => {
                        deleteContact(contact);
                      }}
                    >
                      Delete
                    </Button>
                  </Col>
                  <Col span={4}>
                    <List.Item key={contact._id}>
                      <List.Item.Meta
                        title={contact.name}
                        description={contact.email}
                      />
                    </List.Item>
                  </Col>
                </Row>
              </Card>
            ))}
          </Spin>
          <Divider orientation="left">
            <Space>
              <Button
                icon={<PlusCircleOutlined />}
                type="primary"
                shape="round"
                onClick={() => setShowRoiModal(true)}
              ></Button>
              Area of Interest
            </Space>
          </Divider>
          {roiPoints.length > 0 ? (
            <Form.Item label="AOI Color" name="aoi_color" valuePropName="value">
              <ColorPicker format="rgb" />
            </Form.Item>
          ) : null}
          <Divider orientation="left"> </Divider>
          <Form.Item wrapperCol={{ offset: 8, span: 8 }}>
            <Space>
              <Button type="primary" htmlType="submit" disabled={!enableSave}>
                Save
              </Button>
              <Popconfirm
                title="Are you sure to delete this rule?"
                onConfirm={onDeleteRule}
                okText="Yes"
                cancelText="No"
              >
                <Button type="primary" danger>
                  Delete Rule
                </Button>
              </Popconfirm>
            </Space>
          </Form.Item>
        </Form>
        {showFilterModal ? (
          <NewConditionModal
            types={unusedConditionTypes}
            onCancel={() => setShowFilterModal(false)}
            onFinish={onNewFilter}
          />
        ) : null}
        {showTriggerModal ? (
          <NewConditionModal
            types={unusedConditionTypes}
            onCancel={() => setShowTriggerModal(false)}
            onFinish={onNewTrigger}
          />
        ) : null}
        <Modal
          visible={showContactsModal}
          title="Select Contacts to Add"
          onCancel={() => setShowContactsModal(false)}
          footer={null}
        >
          <SelectContactsForm
            onAccept={(newContacts: Contact[]) => {
              // Add new contacts to the rule
              setContacts([...contacts, ...newContacts]);
              setShowContactsModal(false);
              setEnableSave(true);
            }}
            existingContacts={contacts}
          />
        </Modal>
        <Modal
          visible={showRoiModal}
          title="Select the ROI"
          onCancel={() => setShowRoiModal(false)}
          footer={null}
          width={845}
        >
          {props.streamId !== undefined && (
            <SelectRoiForm
              imagePath={
                window._env_.REACT_APP_PCS_API_URL + "frames/" + props.streamId
              }
              onAccept={(points: Point[]) => {
                // Add points
                setRoiPoints(points);
                setShowRoiModal(false);
                setEnableSave(true);
              }}
              onCancel={() => setShowRoiModal(false)}
              initialPoints={roiPoints}
            />
          )}
        </Modal>
      </Panel>
    </Collapse>
  );
}

export default RuleConfig;
