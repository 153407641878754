import {
  Form,
  Input,
  Button,
  Space,
  InputNumber,
  notification,
  Radio,
} from "antd";
import Stream from "../../../../../../models/Stream";
import { StreamsAPIHelper } from "../../../../../../api-helpers/StreamsAPIHelper";

interface NewStreamFormProps {
  onFinish: () => void;
  onCancel: () => void;
}

const wrapperColProps = {
  sm: { push: 8, span: 16, flex: "auto" },
  xs: { offset: 0, span: 18 },
};

function NewStreamForm(props: NewStreamFormProps) {
  const onFinish = (values: any) => {
    let stream = new Stream();
    stream.url = values["url"];
    stream.name = values["name"];
    stream.fps = values["fps"];
    stream.transport = values["transport"];
    
    StreamsAPIHelper.createSingleAsync(stream).then((r) => {
      if (r) {
        notification.success({
          message: "Stream Created",
          description: "Stream created successfully",
        });
      } else {
        notification.error({
          message: "Error",
          description: "Error while creating the stream.",
        });
      }
    });
  };

  // const onFinishFailed = (errorInfo: any) => {
  //   console.log("Failed:", errorInfo);
  // };

  // if (this.state.finished) {
  //   return <Navigate to="/streams/1" />;
  // }

  return (
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: "Please input a valid name!" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="RTSP Endpoint"
        name="url"
        rules={[
          { required: true, message: "Please input a valid rtsp endpoint!" },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="FPS"
        name="fps"
        rules={[{ required: true, message: "Please input a valid value" }]}
      >
        <InputNumber />
      </Form.Item>
      <Form.Item
        label="Transport type"
        name="transport"
        rules={[{ required: true, message: "Please input a valid value" }]}
      >
        <Radio.Group
        options={["tcp","udp"]}
        // onChange={onChange4}
        value={"tcp"}
        optionType="button"
        buttonStyle="solid"
      />
      </Form.Item>
      <Form.Item wrapperCol={wrapperColProps}>
        <Space>
          <Button type="primary" htmlType="submit" style={{ minWidth: "80px" }}>
            Save
          </Button>
          <Button
            type="primary"
            danger
            style={{ minWidth: "80px" }}
            onClick={props.onCancel}
          >
            Cancel
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}

export default NewStreamForm;
