import { Table, Tag, Button, Tooltip, Row, Col, Breakpoint } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import Stream from "../../../../../../models/Stream";

interface DataRecord {
  _id: string;
  name: string;
  tags: Array<String>;
  alerts: string;
  lastAlert: string;
}

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Status",
    key: "tags",
    dataIndex: "tags",
    render: (tags: Array<string>) => (
      <span>
        {tags.map((tag) => {
          let color: string = "#ffa940";
          switch (tag) {
            case "Alarm":
              color = "volcano";
              break;
            case "Active":
              color = "green";
              break;
            case "Inactive":
              color = "lightgray";
              break;
            default:
              break;
          }
          return (
            <Tag color={color} key={tag}>
              {tag.toUpperCase()}
            </Tag>
          );
        })}
      </span>
    ),
  },
  {
    title: "Alerts",
    dataIndex: "alerts",
    key: "alerts",
    responsive: ["lg"] as Breakpoint[],
  },
  {
    title: "Last Alert",
    dataIndex: "lastAlert",
    key: "lastAlert",
    responsive: ["lg"] as Breakpoint[],
  },
  {
    title: "",
    render: (data: DataRecord) => (
      <div>
        <Row gutter={[60, 10]} justify="space-evenly">
          <Col>
            <Link to={"/services/config/" + data._id}>
              <Tooltip title="Service Configuration">
                <Button
                  type="primary"
                  shape="round"
                  icon={<SettingOutlined />}
                ></Button>
              </Tooltip>
            </Link>
          </Col>
        </Row>
      </div>
    ),
  },
];

interface StreamAIServicesListProps {
  stream: Stream;
}

function StreamAIServicesList(props: StreamAIServicesListProps) {
  let service_data = [
    {
      _id: props.stream._id,
      name: "Human detection and PPE.",
      tags: ["Active"],
      alerts: "Yes",
      lastAlert: "2 hours ago.",
    },
  ];
  return (
    <Table columns={columns} dataSource={service_data} pagination={false} />
  );
}

export default StreamAIServicesList;
