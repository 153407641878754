import React from "react";
import { Card, Col, Row, Space } from "antd";
import { HatColorsDistribution } from "./subcomponents/HatColorsDistribution";
import { AlertsDailyDistribution } from "./subcomponents/AlertsDailyDistribution";
import { DetectionsByStream } from "./subcomponents/DetectionsByStream";
import { AlertsByStream } from "./subcomponents/AlertsByStream";

interface DashboardProps {}

interface DashboardState {}

class Dashboard extends React.Component<DashboardProps, DashboardState> {
  render() {
    let now = new Date();
    let yesterday = new Date();
    yesterday.setDate(now.getDate() - 1);
    let aWeekAgo = new Date();
    aWeekAgo.setDate(now.getDate() - 7);
    let aMonthAgo = new Date();
    aMonthAgo.setDate(now.getDate() - 7);
    let today_start = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate()
    );
    return (
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <Row gutter={[10, 10]} justify="space-between">
          <Col xl={12} lg={24} style={{ width: "100%", height: "100%" }}>
            <Card
              title="Current Detections (real-time)"
              style={{ width: "100%", height: "100%" }}
            >
              <DetectionsByStream />
            </Card>
          </Col>
          <Col xl={12} lg={24} style={{ width: "100%", height: "100%" }}>
            <Card
              title="Alerts by Stream (today)"
              style={{ width: "100%", height: "100%" }}
            >
              <AlertsByStream startDate={today_start} endDate={now} />
            </Card>
          </Col>
          <Col xl={12} lg={24} style={{ width: "100%", height: "100%" }}>
            <Card
              title="Hard Color Distribution"
              style={{ width: "100%", height: "100%" }}
            >
              <HatColorsDistribution startDate={aWeekAgo} endDate={now} />
            </Card>
          </Col>
          <Col xl={12} lg={24} style={{ width: "100%", height: "100%" }}>
            <Card
              title="Alerts Daily Distribution (by trigger type)"
              style={{ width: "100%", height: "100%" }}
            >
              <AlertsDailyDistribution startDate={aWeekAgo} endDate={now} />
            </Card>
          </Col>
        </Row>
      </Space>
    );
  }
}

export default Dashboard;
