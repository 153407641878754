import { Button, Card, Modal, Space } from "antd";
import { useState } from "react";
import Summary from "../../dashboard/subcomponents/Summary";
import StreamsTable from "./subcomponents/StreamsTable";
import { PlusCircleOutlined } from "@ant-design/icons";
import NewStreamForm from "../create/subcomponents/NewStreamForm";

function StreamsList() {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <Card
          title={
            <Space size={20}>
              Streams
              <Button
                type="primary"
                shape="round"
                icon={<PlusCircleOutlined />}
                onClick={showModal}
              >
                New
              </Button>
            </Space>
          }
        >
          <StreamsTable />
        </Card>
        <Modal
          title="New Stream"
          visible={isModalOpen}
          footer={null}
          onCancel={handleCancel}
          destroyOnClose
        >
          <NewStreamForm
            onFinish={handleOk}
            onCancel={handleCancel}
          ></NewStreamForm>
        </Modal>
      </Space>
    </>
  );
}

export default StreamsList;
